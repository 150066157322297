import styled from 'styled-components'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import {
  space,
  position,
  color,
  typography,
  border,
  layout,
  flexbox,
  variant,
} from 'styled-system'

export const StyledForm = styled(Form)`
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
`

export const StyledInput = styled.input`
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
`

export const StyledField = styled(Field)`
  border: solid;
  border-width: 1px;
  height: 20px;
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
`

export const StyledError = styled(ErrorMessage)`
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
`

export const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;       
  width: 100%;
  font-size: 12px;
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
`
