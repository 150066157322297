import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import usePreviewQuery, {
  GET_ENTRIES_BY_CONTENT_TYPE_ID,
} from '@hooks/usePreviewQuery'
import { useLocale } from '@hooks/'
import { recursivelyGetLinkedEntriesAndSpreadFields, parseVideo } from '@utils/'
import Login from '@components/Login'

const LoginApolloCPAContainer = ({ location }) => {
  const { locale } = useLocale()
  const {
    loading: loadingSiteInfo,
    error: errorSiteInfo,
    data: dataSiteInfo,
  } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID,
    id: 'siteInformationAndBranding',
    locale,
  })

  const [loginInfo, setLoginInfo] = useState({})

  useEffect(() => {
    if (!loadingSiteInfo && !errorSiteInfo && dataSiteInfo) {
      const {
        entries: { includes, items },
      } = dataSiteInfo

      const Entry = includes?.Entry || []
      const Asset = includes?.Asset || []
      const { sys, fields } = items?.[0]
      let mappedSiteInfo = {
        ...fields,
        id: sys?.id,
        type: sys?.contentType?.sys?.id,
      }

      mappedSiteInfo = recursivelyGetLinkedEntriesAndSpreadFields(
        mappedSiteInfo,
        Entry,
        Asset
      )

      const login = location?.state?.login

      mappedSiteInfo = {
        ...mappedSiteInfo,
        login: login || mappedSiteInfo?.login,
        previousPath: location?.state?.redirectedFrom || '/',
        bgVideo: parseVideo(mappedSiteInfo?.passwordProtectionVideoBg),
      }

      setLoginInfo(mappedSiteInfo)
    }
  }, [dataSiteInfo, errorSiteInfo, loadingSiteInfo, location])

  return (
    <Login
      login={loginInfo?.login}
      previousPath={loginInfo?.previousPath}
      bgVideo={loginInfo?.bgVideo}
    />
  )
}

LoginApolloCPAContainer.propTypes = {}

export default LoginApolloCPAContainer
