import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'

import LoginGatsbyCDAContainer from '@containers/Login'

const Login = ({ pageContext, data, location }) => {
  const seo = { locale: pageContext?.locale, metaTitle: pageContext?.name }

  return (
    <Layout {...pageContext} seo={seo}>
      <LoginGatsbyCDAContainer location={location} />
    </Layout>
  )
}

Login.propTypes = {}

export default Login
