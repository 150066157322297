import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import {
  StyledForm,
  StyledInput,
  StyledLabel,
  StyledField,
  StyledError,
} from './styles'
import { Formik } from 'formik'
import { Button } from '@components/Button'
import { useTriggerTransition } from 'gatsby-plugin-transition-link'
import { fadeOut, fadeIn } from '@utils/'
import css from '@styled-system/css'
import Box from '@components/Box'
import Icon from '@components/Icon'
import { ThemeContext } from 'styled-components'

const LoginForm = ({ theme = 'light', previousPath, login }) => {
  const themePackage = useContext(ThemeContext)
  const triggerTransition = useTriggerTransition({
    to: previousPath,
    linkState: { authenticated: true },
    replace: true,
    exit: {
      trigger: ({ exit, node }) => fadeOut(exit, node),
      length: 1,
    },
    entry: {
      trigger: ({ entry, node }) => fadeIn(entry, node),
      delay: 1,
      length: 1,
    },
  })
  // http://localhost:8000/work/elder-scrolls-online-elsweyr

  const validate = values => {
    const errors = {}
    if (!values.username) {
      errors.username = 'Required'
    } else if (values.username !== login) {
      errors.username = 'Incorrect Username'
    }

    if (!values.password) {
      errors.password = 'Required'
    } else if (values.password !== process.env.GATSBY_ARTICLE_PASSWORD) {
      errors.password = 'Incorrect Password'
    }

    return errors
  }

  const handleSubmit = e => {
    triggerTransition(e)
  }

  return (
    <Box
      mx={[3, 5, 6]}
      height="300px"
      maxWidth="800px"
      width="100%"
      bg={`${theme}.bg`}
    >
      <Formik
        initialValues={{
          username: '',
          password: '',
        }}
        validate={validate}
        onSubmit={handleSubmit}
      >
        <StyledForm
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100%"
          px={[3, 5, 6, 7]}
        >
          <Icon
            fill={themePackage?.colors?.[theme]?.text}
            glyph="padlock"
            height="30px"
            width="30px"
            viewBox="0 0 20 20"
          />
          <StyledLabel color={`${theme}.text`} htmlFor="username">
            USERNAME*
            <StyledField name="username" type="text" autocomplete="username" />
            <Box color={`${theme}.alert`} height="30px" width="100%">
              <StyledError name="username" />
            </Box>
          </StyledLabel>
          <StyledLabel color={`${theme}.text`} htmlFor="password">
            PASSWORD*
            <StyledField
              name="password"
              type="password"
              autocomplete="current-password"
            />
            <Box color={`${theme}.alert`} height="30px" width="100%">
              <StyledError name="password" />
            </Box>
          </StyledLabel>
          <Button
            type="submit"
            color={`${theme}.ctaBtnColor`}
            bg={`${theme}.ctaBtnBg`}
            css={css({
              textTransform: 'uppercase',
              letterSpacing: '0',
              '&:hover': {
                bg: `${theme}.ctaBtnHover`,
                color: `${theme}.ctaBtnColor`,
              },
            })}
            fontSize={4}
            height="40px"
            minWidth="200px"
            textTransform="uppercase"
          >
            Submit
          </Button>
        </StyledForm>
      </Formik>
    </Box>
  )
}

LoginForm.propTypes = {}

export default LoginForm
