import React from 'react'
import PropTypes from 'prop-types'
import { navigate, useStaticQuery, graphql } from 'gatsby'
import Login from '@components/Login'
import { parseVideo } from '@utils/'

const LoginGatsbyCDAContainer = ({ location }) => {
  const {
    defaultSiteBranding: { nodes },
  } = useStaticQuery(graphql`
    query DefaultLoginQuery {
      defaultSiteBranding: allContentfulSiteInformationAndBranding(limit: 1) {
        nodes {
          login
          passwordProtectionVideoBg {
            ...videoFragment
          }
        }
      }
    }
  `)

  const defaultLogin = nodes?.[0]?.login
  const login = location?.state?.login || defaultLogin
  const previousPath = location?.state?.redirectedFrom || '/'
  const bgVideo = parseVideo(nodes?.[0]?.passwordProtectionVideoBg)

  return <Login login={login} previousPath={previousPath} bgVideo={bgVideo} />
}

LoginGatsbyCDAContainer.propTypes = {}

export default LoginGatsbyCDAContainer
