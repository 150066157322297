import React from 'react'
import PropTypes from 'prop-types'

import Box from '@components/Box'
import Video from '@components/Video'
import LoginForm from './LoginForm'

const Login = ({ bgVideo, ...rest }) => {
  return (
    <Box
      height="100vh"
      width="100vw"
      ml="-50vw"
      left="50%"
      position="relative"
      overflow="hidden"
    >
      {bgVideo && (
        <Video
          {...bgVideo}
          height="100vh"
          videoFit="Cover"
          size="Full Width"
          notInteractable
        />
      )}
      <Box
        position="absolute"
        top="0"
        right="0"
        left="0"
        bottom="0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <LoginForm {...rest} />
      </Box>
    </Box>
  )
}

Login.propTypes = {}

export default Login
